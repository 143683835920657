/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ActionBlockDto {
  blockMovements?: boolean
  blockCheckOut?: boolean
  reason: string
  blockedBy?: ExecutedByDto | null
  source: string
}

export interface ActionBlockProjection {
  blockMovements?: boolean
  blockCheckOut?: boolean
  reason: string
  blockedBy?: string | null
}

export interface ActionProjection {
  type: string
  value?: string | null
}

export enum ActionType {
  AutoCreateShiftMove = 'AutoCreateShiftMove'
}

export enum AdrClass {
  Explosives = 'Explosives',
  FlammableGases = 'FlammableGases',
  NonFlammableNonToxicGases = 'NonFlammableNonToxicGases',
  ToxicGases = 'ToxicGases',
  FlammableLiquids = 'FlammableLiquids',
  FlammableSolidsSelfReactive = 'FlammableSolidsSelfReactive',
  SubstancesSpontaneouslyCombustible = 'SubstancesSpontaneouslyCombustible',
  SubstancesEmitFlammableGasesInContactWithWater = 'SubstancesEmitFlammableGasesInContactWithWater',
  OxidizingSubstances = 'OxidizingSubstances',
  OrganicPeroxides = 'OrganicPeroxides',
  ToxicSubstances = 'ToxicSubstances',
  InfectiousSubstances = 'InfectiousSubstances',
  Radioactive = 'Radioactive',
  Corrosives = 'Corrosives',
  Miscellaneous = 'Miscellaneous'
}

export interface AutoShiftMoveRuleUpsertDto {
  conditions?: RuleConditionDto[]
  triggers?: RuleTriggerDto[]
  actions?: RuleActionDto[]
}

export interface AzureAISearchFacet {
  /** @format int32 */
  count?: number
  value: string
}

export interface BlockDetailsDto {
  /** @format uuid */
  id: string
  name: string
  prefix: string
  slotType?: SlotType | null
  slotSize?: SlotSize | null
  isLiftOnOff?: boolean | null
  isLoadingDock?: boolean | null
  /** @format int32 */
  rowsFrom?: number | null
  /** @format int32 */
  rowsTo?: number | null
  /** @format int32 */
  depthFrom?: number | null
  /** @format int32 */
  depthTo?: number | null
  /** @format int32 */
  levels?: number | null
  /** @format uuid */
  yardZoneId: string
  yardZoneName: string
  /** @format uuid */
  siteId: string
  siteCode: string
  isActive?: boolean
  linkableRows?: LinkableRowsPairDto[]
}

export interface BlockDto {
  /** @format uuid */
  id: string
  name: string
  prefix: string
  slotType?: SlotType | null
  /** @format int32 */
  slotsCount: number
  /** @format uuid */
  yardZoneId: string
  yardZoneName: string
  yardZone?: BlockYardZoneDto | null
  siteCode: string
  isActive: boolean
  isLiftOnOff?: boolean
}

export interface BlockYardOrderDto {
  blockMovements?: boolean
  blockCheckOut?: boolean
  reason: string
}

export interface BlockYardZoneDto {
  /** @format uuid */
  id: string
  name: string
  yardZoneType: YardZoneType
  isLiftOnOff?: boolean
  /** @format uuid */
  siteId: string
  siteCode: string
  siteName: string
  /** @format int32 */
  blocksCount: number
}

export interface BlockedProcessDto {
  processName: ProcessName
  conditions?: ConditionDto[]
}

export interface BlockedProcessProjection {
  processName: string
  conditions?: ConditionProjection[]
}

export interface CancelYardTaskDto {
  /** Optional reason providing additional details about the cancellation of the move. */
  reason?: string | null
}

export interface CargoDto {
  product?: string | null
  article?: string | null
  configuration?: string | null
  lotBatch?: string | null
  /** The ADR classes of dangerous goods to which the products in this cargo belong to. */
  adrClasses?: AdrClass[]
  /** The GHS classes of dangerous goods to which the products in this cargo belong to. */
  ghsClasses?: GhsClassDto[]
  /** The code of the warehouse where this cargo will be (un)loaded. */
  warehouseCode?: string | null
  /** The code of the gate where this cargo will be (un)loaded. */
  gateCode?: string | null
}

export interface CargoUpsertDto {
  product?: string | null
  article?: string | null
  configuration?: string | null
  lotBatch?: string | null
  /**
   * The ADR classes of dangerous goods to which the products in this cargo belong to.
   * @uniqueItems true
   */
  adrClasses?: AdrClass[]
  /**
   * The GHS classes of dangerous goods to which the products in this cargo belong to.
   * @uniqueItems true
   */
  ghsClasses?: GhsClass[]
  /** The code of the warehouse where this cargo will be (un)loaded. */
  warehouseCode?: string | null
  /** The code of the gate where this cargo will be (un)loaded. */
  gateCode?: string | null
}

export type CheckInBargeDto = CheckInDto & {
  /** The transport identification number associated with the barge being checked in. */
  transportIdentificationNumber: string
}

export interface CheckInDetailsDto {
  transportCompanyBooked?: string | null
  transportCompanyArrived?: string | null
  licensePlateTruck?: string | null
  licensePlateTrailer?: string | null
  transportIdentificationNumber?: string | null
  chassisNumber?: string | null
  /**
   * @format date-time
   * @example "2024-11-14T10:13:01.6493484"
   */
  checkedInAt?: string | null
  meansOfTransport: MeansOfTransport
  /**
   * @format date
   * @example "2024-11-14"
   */
  pickedUpAtTerminalDate?: string | null
}

export interface CheckInDto {
  /** The shipping unit number. */
  shippingUnitNumber: string
  /** The shipping unit kind. */
  shippingUnitKind: string
  /** The name of the transport company associated with the check in. */
  transportCompany: string
  /** The location where shipping unit is intended to be placed. */
  location: string
  /**
   * The date and time when the check in was performed.
   * @format date-time
   * @example "2024-11-14T10:13:01.6493484"
   */
  checkedInAt?: string | null
  /**
   * The ID of the operator that finished the in move. Applies only for the immediate set in.
   * @format uuid
   */
  setInBy?: string | null
  /**
   * The date and time when the shipping unit was set in. Applies only for the immediate set in.
   * @format date-time
   * @example "2024-11-14T10:13:01.6493484"
   */
  setInAt?: string | null
  /**
   * The date when trucker picked up the shipping unit at the terminal. This date is necessary for Ixolution to calculate the demurrage and detention dates.
   * @format date
   * @example "2024-11-14"
   */
  pickedUpAtTerminalDate?: string | null
  /** The remarks. */
  remarks?: string | null
  /** The means of transportation used for check in. */
  meansOfTransport: MeansOfTransport
}

export type CheckInRailDto = CheckInDto & {
  /** The transport identification number associated with the train transport being checked in. */
  transportIdentificationNumber: string
}

export type CheckInTruckDto = CheckInDto & {
  /** The license plate number of the truck being checked in. */
  licensePlateTruck: string
  /** The license plate number of the trailer attached to the truck. */
  licensePlateTrailer?: string | null
  /** The chassis number. */
  chassisNumber?: string | null
}

export interface CheckInUpsertDto {
  bookedTransportCompany?: string | null
  licensePlateTruck?: string | null
  licensePlateTrailer?: string | null
  transportIdentificationNumber?: string | null
  meansOfTransport?: MeansOfTransport | null
}

export type CheckOutBargeDto = CheckOutDto & {
  /** The transport identification number associated with the barge being checked out. */
  transportIdentificationNumber: string
}

export interface CheckOutDetailsDto {
  transportCompanyBooked?: string | null
  transportCompanyArrived?: string | null
  licensePlateTruck?: string | null
  licensePlateTrailer?: string | null
  transportIdentificationNumber?: string | null
  /**
   * @format date-time
   * @example "2024-11-14T10:13:01.6493484"
   */
  checkedOutAt?: string | null
  meansOfTransport: MeansOfTransport
}

export interface CheckOutDto {
  /** The name of the transport company associated with the checkout. */
  transportCompany: string
  /**
   * The date and time when the checkout was performed.
   * @format date-time
   * @example "2024-11-14T10:13:01.6493484"
   */
  checkedOutAt?: string | null
  /**
   * The ID of the operator that finished the out move. Applies only for the immediate init out.
   * @format uuid
   */
  setOutBy?: string | null
  /**
   * The date and time when the shipping unit was init out. Applies only for the immediate init out.
   * @format date-time
   * @example "2024-11-14T10:13:01.6493484"
   */
  setOutAt?: string | null
  /** The means of transportation used for checkout. */
  meansOfTransport: MeansOfTransport
}

export type CheckOutRailDto = CheckOutDto & {
  /** The transport identification number associated with the train transport being checked out. */
  transportIdentificationNumber: string
}

export type CheckOutTruckDto = CheckOutDto & {
  /** The license plate number of the truck being checked out. */
  licensePlateTruck: string
  /** The license plate number of the trailer attached to the truck. */
  licensePlateTrailer?: string | null
}

export interface CheckOutUpsertDto {
  bookedTransportCompany?: string | null
  licensePlateTruck?: string | null
  licensePlateTrailer?: string | null
  transportIdentificationNumber?: string | null
  meansOfTransport?: MeansOfTransport | null
}

export interface ConditionDto {
  type: string
  values?: string[]
}

export interface ConditionProjection {
  type: string
  values?: string[]
}

export enum ConditionType {
  Site = 'Site',
  YardZone = 'YardZone',
  Block = 'Block',
  Slot = 'Slot'
}

/** DTO with all the properties required to create a block */
export interface ContainerStackBlockCreateDto {
  /** Block name */
  name: string
  prefix: string
  /** @format uuid */
  yardZoneId: string
  slotSize: SlotSize
  /** @format int32 */
  rowsFrom: number
  /** @format int32 */
  rowsTo: number
  /** @format int32 */
  depth: number
  /** @format int32 */
  levels: number
  isLiftOnOff: boolean
  areSlotsLiftOnOff: boolean
  linkedRows?: RowsPairDto[]
}

export interface ContainerStackBlockUpdateDto {
  name: string
  prefix: string
  /** @format int32 */
  rowsTo: number
  /** @format int32 */
  depth: number
  /** @format int32 */
  levels: number
  isLiftOnOff: boolean
  linkedRows?: RowsPairDto[]
}

export enum ContentStatus {
  Empty = 'Empty',
  Full = 'Full'
}

export interface CreatePushNotificationSubscriptionDto {
  endpoint: string
  p256DH: string
  auth: string
}

export interface CreateShiftMoveDto {
  /**
   * The date and time when the move has been queued. Not applicable if the move should be queued now.
   * @format date-time
   * @example "2024-11-14T10:13:01.6493484"
   */
  queuedOn?: string | null
  /**
   * The latest date and time by which the move should be finished.
   * @format date-time
   * @example "2024-11-14T10:13:01.6493484"
   */
  finishBefore?: string | null
  /** Indicates whether the move needs to be treated as urgent. */
  isUrgent?: boolean
  /** Optional remarks providing additional details about the move, such as specific handling instructions or considerations. */
  remark?: string | null
  /** The required destination location to which the shift needs to move. */
  plannedToLocation: string
  /** An alternative location where the shift may be planned to move if the primary location is unavailable or unsuitable. */
  alternativePlannedToLocation?: string | null
}

export interface CurrentOperatorDto {
  /** @format uuid */
  operatorId: string
  isActive: boolean
  sites: SiteDto[]
  handler: HandlerDto
  tables: Record<string, string>
  permissions: PermissionsDto
}

/** The details of the current logged-in user. */
export interface CurrentUserDetailsDto {
  /** @format uuid */
  userId: string
  firstName: string
  lastName: string
  emailAddress: string
  userPreference?: UserPreferenceDetailsDto | null
  tables?: Record<string, string>
  permissions: PermissionsDto
  roles: RoleType[]
}

export interface DefaultBlockDto {
  /** @format uuid */
  blockId: string
}

export interface DefaultSlotDto {
  /** @format uuid */
  slotId: string
}

export interface ExecutedByDto {
  firstName: string
  lastName: string
}

export interface ExportQueryDto {
  filter?: string | null
  search?: string | null
  searchFields?: string[]
  orderBy?: string[]
  select?: string[]
  headerMappings?: Record<string, string>
}

export interface FeatureFlagDto {
  name: string
  isEnabled: boolean
}

export interface FillingPipeBlockCreateDto {
  name: string
  prefix: string
  /** @format uuid */
  yardZoneId: string
  /** @format int32 */
  rowsFrom: number
  /** @format int32 */
  rowsTo: number
  isLiftOnOff: boolean
  areSlotsLiftOnOff: boolean
}

export interface FillingPipeBlockUpdateDto {
  name: string
  prefix: string
  /** @format int32 */
  rowsTo: number
  isLiftOnOff: boolean
}

export interface FinishInMoveDto {
  /** The slot on which the shipping unit should be placed. */
  slot: string
  /**
   * The date and time when the move has been finished. Not applicable if the move should be finished now.
   * @format date-time
   * @example "2024-11-14T10:13:01.6493484"
   */
  inAt?: string | null
  /**
   * The ID of the operator that finished the move.
   * @format uuid
   */
  operatorId: string
  /** An optional remark related to the move finishing. */
  finishingRemark?: string | null
}

export interface FinishLoadingUnloadingDto {
  /**
   * @format date-time
   * @example "2024-11-14T10:13:01.6493484"
   */
  loadingUnloadingFinishedAt: string
  contentStatus: ContentStatus
  setAvailableForPickup: boolean
  seals?: string[] | null
}

export interface FinishOutMoveDto {
  /**
   * The ID of the Yard Order used for swap. Applicable only if a swap should take place during the finish of an out move.
   * @format uuid
   */
  toBeSwappedYardOrderId?: string | null
  /**
   * The date and time when the move has been finished. Not applicable if the move should be finished now.
   * @format date-time
   * @example "2024-11-14T10:13:01.6493484"
   */
  finishedOn?: string | null
  /**
   * The ID of the operator that finished the move.
   * @format uuid
   */
  operatorId: string
  /** An optional remark related to the move finishing. */
  finishingRemark?: string | null
}

export interface FinishShiftMoveDto {
  /** The slot to which the shipping unit should be shifted. */
  slot: string
  /**
   * The date and time when the move has been finished. Not applicable if the move should be finished now.
   * @format date-time
   * @example "2024-11-14T10:13:01.6493484"
   */
  finishedOn?: string | null
  /**
   * The ID of the operator that finished the move.
   * @format uuid
   */
  operatorId: string
  /** An optional remark related to the move finishing. */
  finishingRemark?: string | null
}

export enum FlowStatus {
  Start = 'Start',
  Flow = 'Flow',
  End = 'End'
}

export interface GateBlockCreateDto {
  name: string
  prefix: string
  /** @format uuid */
  yardZoneId: string
  /** @format int32 */
  rowsFrom: number
  /** @format int32 */
  rowsTo: number
  isLoadingDock: boolean
  isLiftOnOff: boolean
  areSlotsLiftOnOff: boolean
}

export interface GateBlockUpdateDto {
  name: string
  prefix: string
  /** @format int32 */
  rowsTo: number
  isLiftOnOff: boolean
}

export enum GhsClass {
  GHS01 = 'GHS01',
  GHS02 = 'GHS02',
  GHS03 = 'GHS03',
  GHS04 = 'GHS04',
  GHS05 = 'GHS05',
  GHS06 = 'GHS06',
  GHS07 = 'GHS07',
  GHS08 = 'GHS08',
  GHS09 = 'GHS09'
}

export interface GhsClassDto {
  code: GhsClass
  name: string
}

export interface HandlerDetailsDto {
  /** @format uuid */
  id: string
  name: string
  assetNumber: string
  type: HandlerType
  yardZones: HandlerYardZoneDto[]
  isActive: boolean
}

export interface HandlerDto {
  /** @format uuid */
  id: string
  name: string
}

export enum HandlerType {
  ReachStacker = 'ReachStacker',
  TerminalTruck = 'TerminalTruck'
}

export interface HandlerUpsertDto {
  name: string
  assetNumber: string
  type: HandlerType
  yardZonesIds: string[]
}

export interface HandlerYardZoneDto {
  /** @format uuid */
  id: string
  name: string
  siteName: string
  isActive: boolean
}

export type HttpValidationProblemDetails = ProblemDetails & {
  /** @example {"key":["message"]} */
  errors?: Record<string, string[]>
}

export type InMoveDetailsDto = YardTaskDetailsDto & {
  transportCompany?: string | null
  licensePlateTruck?: string | null
  licensePlateTrailer?: string | null
  chassisNumber?: string | null
  transportIdentificationNumber?: string | null
  plannedToLocation?: YardLocationDto | null
  actualToLocation?: ShippingUnitLocationDto | null
}

export interface InitializeCountriesParams {
  force?: boolean
}

export interface InitializeSitesParams {
  force?: boolean
}

export interface InitializedCountries {
  /** @format int32 */
  count?: number
}

export interface InitializedSites {
  /** @format int32 */
  count?: number
}

export interface InternalProcessingErrorDto {
  errorMessage: string
  processName: ProcessName
  parentProcessName?: ProcessName | null
  /**
   * @format date-time
   * @example "2024-11-14T10:13:01.6493484"
   */
  timestamp: string
}

export interface LinkableRowsPairDto {
  /** @format int32 */
  row1: number
  /** @format int32 */
  row2: number
  isLinked: boolean
  canBeUnlinked: boolean
}

export interface MailDto {
  targetMail: string
  targetName: string
  subject: string
  body: string
}

export enum MeansOfTransport {
  None = 'None',
  Truck = 'Truck',
  Rail = 'Rail',
  Barge = 'Barge'
}

export enum MoveType {
  In = 'In',
  Shift = 'Shift',
  Out = 'Out'
}

export interface NotificationConditionDto {
  /** The type of condition that must be met for a particular condition to be sent. */
  notificationConditionType: NotificationConditionType
  /** One or more values to apply on a particular condition. */
  propertyValues?: any[]
}

export interface NotificationConditionProjection {
  conditionType?: string | null
  values?: string[]
}

export enum NotificationConditionType {
  ShippingUnitType = 'ShippingUnitType',
  ShippingUnitKind = 'ShippingUnitKind',
  ShippingUnitLength = 'ShippingUnitLength',
  Content = 'Content',
  DangerousGoodsRegulations = 'DangerousGoodsRegulations',
  Customer = 'Customer',
  ShippingLine = 'ShippingLine',
  TransportCompany = 'TransportCompany'
}

export enum NotificationDeliveryMethod {
  Email = 'Email',
  SMS = 'SMS'
}

export interface NotificationRecipientDto {
  /** The method how to deliver the notification. */
  deliveryMethod: NotificationDeliveryMethod
  /** The destination to which the notification will be sent. */
  destination: string
}

export interface NotificationRecipientProjection {
  recipientType?: string | null
  value?: string | null
}

/** The details of a single subscription to a particular notification. */
export interface NotificationSubscriptionDetailsDto {
  /**
   * The identifier of this subscription.
   * @format uuid
   */
  id: string
  /** The user-friendly name of this subscription. */
  name: string
  /** The type of notification that will be handled by this subscription. */
  notificationType: NotificationType
  /**
   * The identifier of the site to which this subscription will apply.
   * @format uuid
   */
  siteId: string
  /**
   * The identifier of the yard-zone to which this subscription will apply.
   * @format uuid
   */
  yardZoneId?: string | null
  /**
   * The identifier of the block to which this subscription will apply.
   * @format uuid
   */
  blockId?: string | null
  /**
   * The minimum amount of shipping units that can be present on the associated location, before a notification is sent.
   * @format int32
   */
  minimumShippingUnits?: number | null
  /**
   * The maximum amount of shipping units that can be present on the associated location, before a notification is sent.
   * @format int32
   */
  maximumShippingUnits?: number | null
  /** One or more recipients to which notifications can be sent. */
  notificationRecipients?: NotificationRecipientDto[]
  /** One or more conditions that must be met, before notifications will be sent. */
  notificationConditions?: NotificationConditionDto[]
}

export interface NotificationSubscriptionProjection {
  id: string
  name?: string | null
  notificationType?: string | null
  site?: string | null
  yardZone?: string | null
  block?: string | null
  notificationRecipients?: NotificationRecipientProjection[]
  notificationConditions?: NotificationConditionProjection[]
  /** @format date-time */
  createdAt?: string | null
}

export interface NotificationSubscriptionProjectionIEnumerableAzureAISearchCollectionResponse {
  '@search.facets'?: Record<string, AzureAISearchFacet[]>
  '@odata.context': string
  /** @format int32 */
  '@odata.count'?: number | null
  value: NotificationSubscriptionProjection[]
}

/** The DTO used to create or update a subscription to a particular notification. */
export interface NotificationSubscriptionUpsertDto {
  /** The user-friendly name of this subscription. */
  name: string
  /** The type of notification that will be handled by this subscription. */
  notificationType: NotificationType
  /**
   * The identifier of the site to which this subscription will apply.
   * @format uuid
   */
  siteId: string
  /**
   * The identifier of the yard-zone to which this subscription will apply.
   * @format uuid
   */
  yardZoneId?: string | null
  /**
   * The identifier of the block to which this subscription will apply.
   * @format uuid
   */
  blockId?: string | null
  /**
   * The minimum amount of shipping units that can be present on the associated location, before a notification is sent.
   * @format int32
   */
  minimumShippingUnits?: number | null
  /**
   * The maximum amount of shipping units that can be present on the associated location, before a notification is sent.
   * @format int32
   */
  maximumShippingUnits?: number | null
  /** One or more recipients to which notifications can be sent. */
  notificationRecipients?: NotificationRecipientDto[]
  /** One or more conditions that must be met, before notifications will be sent. */
  notificationConditions?: NotificationConditionDto[]
}

export enum NotificationType {
  TransportArrivedForDropOff = 'TransportArrivedForDropOff',
  InOutMoveQueued = 'InOutMoveQueued',
  ShippingUnitShift = 'ShippingUnitShift',
  ShippingUnitAtLoadingDock = 'ShippingUnitAtLoadingDock',
  TransportArrivedForPickup = 'TransportArrivedForPickup',
  ContentUpdate = 'ContentUpdate',
  MinMaxNumberOfShippingUnitsReached = 'MinMaxNumberOfShippingUnitsReached'
}

export interface ODataAddressDto {
  streetAndNumber?: string | null
  box?: string | null
  building?: string | null
  city?: string | null
  zip?: string | null
  country?: string | null
}

export interface ODataBlockDto {
  /** @format uuid */
  id: string
  name: string
  prefix: string
  slotType?: SlotType | null
  /** @format int32 */
  slotsCount: number
  /** @format uuid */
  yardZoneId: string
  yardZoneName: string
  yardZone?: ODataYardZoneDto | null
  siteCode: string
  isActive: boolean
  isLiftOnOff?: boolean
  isDefault: boolean
}

/** Defines the response to an OData query, potentially containing multiple items. */
export interface ODataBlockDtoArrayODataCollectionResponse {
  '@odata.context': string
  /** @format int32 */
  '@odata.count'?: number | null
  value: ODataBlockDto[]
}

export interface ODataCustomerDto {
  /** @format uuid */
  id: string
  code: string
}

/** Defines the response to an OData query, potentially containing multiple items. */
export interface ODataCustomerDtoArrayODataCollectionResponse {
  '@odata.context': string
  /** @format int32 */
  '@odata.count'?: number | null
  value: ODataCustomerDto[]
}

export interface ODataHandlerDto {
  /** @format uuid */
  id: string
  name: string
  assetNumber: string
  type: HandlerType
  isActive: boolean
}

/** Defines the response to an OData query, potentially containing multiple items. */
export interface ODataHandlerDtoArrayODataCollectionResponse {
  '@odata.context': string
  /** @format int32 */
  '@odata.count'?: number | null
  value: ODataHandlerDto[]
}

/** Defines an operational block, which includes operational data such as slot occupancy in additional to the configured block details. */
export interface ODataOperationalBlockDto {
  /**
   * The unique ID of the block.
   * @format uuid
   */
  id: string
  /** The code representing the site to which this block belongs. */
  siteCode: string
  /** The name of this block. */
  name: string
  /**
   * The id of the yard-zone to which this block belongs.
   * @format uuid
   */
  yardZoneId: string
  /** The name of the yard-zone to which this block belongs. */
  yardZoneName: string
  /** Gets a value indicating whether the location is active. */
  isActive: boolean
  /**
   * The total number of slots in this block.
   * @format int32
   */
  totalSlotsCount?: number
  /**
   * The total number of free slots in this block.
   * @format int32
   */
  freeSlotsCount?: number
  /**
   * The total number of occupied slots in this block.
   * @format int32
   */
  occupiedSlotsCount?: number
  /**
   * The total number of shipping units belonging to currently ongoing yard visits in this block.
   * @format int32
   */
  yardVisitsCount?: number
}

/** Defines the response to an OData query, potentially containing multiple items. */
export interface ODataOperationalBlockDtoArrayODataCollectionResponse {
  '@odata.context': string
  /** @format int32 */
  '@odata.count'?: number | null
  value: ODataOperationalBlockDto[]
}

/** Defines an operational site, which includes operational data such as slot occupancy in additional to the configured site details. */
export interface ODataOperationalSiteDto {
  /**
   * The unique ID of the site.
   * @format uuid
   */
  id: string
  /** The code representing the site code. */
  code: string
  /**
   * The total number of free slots in this site.
   * @format int32
   */
  freeSlotsCount?: number
  /**
   * The total number of occupied slots in this site.
   * @format int32
   */
  occupiedSlotsCount?: number
  /**
   * The total number of slots in this site.
   * @format int32
   */
  totalSlotsCount?: number
  /**
   * The total number of shipping units belonging to currently ongoing yard visits in this site.
   * @format int32
   */
  yardVisitsCount?: number
}

/** Defines the response to an OData query, potentially containing multiple items. */
export interface ODataOperationalSiteDtoArrayODataCollectionResponse {
  '@odata.context': string
  /** @format int32 */
  '@odata.count'?: number | null
  value: ODataOperationalSiteDto[]
}

/** Defines an operational slot, which includes operational data such as slot occupancy and yard visit details in additional to the configured slot details. */
export interface ODataOperationalSlotDto {
  /**
   * The unique ID of the slot.
   * @format uuid
   */
  id: string
  /** The code representing the site to which this block belongs. */
  siteCode: string
  /** The name of this block. */
  name: string
  /**
   * The number representing the row coordinate of the slot.
   * @format int32
   */
  row?: number | null
  /**
   * The number representing the depth coordinate of the slot.
   * @format int32
   */
  depth?: number | null
  /**
   * The number representing the row coordinate of the slot.
   * @format int32
   */
  level?: number | null
  /** Gets a value indicating whether the location is lift on off. */
  isLiftOnOff?: boolean
  /** Gets a value indicating whether the location is active. */
  isActive?: boolean
  /** Represents the type of slot location. */
  slotType: SlotType
  /** Represents the size of the slot. */
  slotSize?: SlotSize | null
  /** Gets a value indicating the occupancy of the slot. */
  slotStatus: SlotStatus
  /**
   * The id of the block to which this slot belongs.
   * @format uuid
   */
  blockId: string
  /** The name of the block to which this slot belongs. */
  blockName: string
  /**
   * The id of the yard-zone to which this slot belongs.
   * @format uuid
   */
  yardZoneId: string
  /** The name of the yard-zone to which this slot belongs. */
  yardZoneName: string
  /** Collection of yard visit representation for which the slot being currently used as current location */
  yardVisits?: ODataSlotYardVisitDto[]
}

/** Defines the response to an OData query, potentially containing multiple items. */
export interface ODataOperationalSlotDtoArrayODataCollectionResponse {
  '@odata.context': string
  /** @format int32 */
  '@odata.count'?: number | null
  value: ODataOperationalSlotDto[]
}

/** Defines an operational yard-zone, which includes operational data such as slot occupancy in additional to the configured yard-zone details. */
export interface ODataOperationalYardZoneDto {
  /**
   * The unique ID of the yard-zone.
   * @format uuid
   */
  id: string
  /** The code representing the site to which this yard-zone belongs. */
  siteCode: string
  /** The name of this yard-zone. */
  name: string
  /** Gets a value indicating whether the location is active. */
  isActive: boolean
  /**
   * The total number of free slots in this yard-zone.
   * @format int32
   */
  freeSlotsCount?: number
  /**
   * The total number of occupied slots in this yard-zone.
   * @format int32
   */
  occupiedSlotsCount?: number
  /**
   * The total number of slots in this yard-zone.
   * @format int32
   */
  totalSlotsCount?: number
  /**
   * The total number of shipping units belonging to currently ongoing yard visits in this yard-zone.
   * @format int32
   */
  yardVisitsCount?: number
}

/** Defines the response to an OData query, potentially containing multiple items. */
export interface ODataOperationalYardZoneDtoArrayODataCollectionResponse {
  '@odata.context': string
  /** @format int32 */
  '@odata.count'?: number | null
  value: ODataOperationalYardZoneDto[]
}

export interface ODataOperatorDto {
  /** @format uuid */
  id: string
  firstName: string
  lastName: string
  username: string
  isActive: boolean
  siteCodes: string[]
}

/** Defines the response to an OData query, potentially containing multiple items. */
export interface ODataOperatorDtoArrayODataCollectionResponse {
  '@odata.context': string
  /** @format int32 */
  '@odata.count'?: number | null
  value: ODataOperatorDto[]
}

export interface ODataShippingLineDto {
  /** @format uuid */
  id: string
  code: string
}

/** Defines the response to an OData query, potentially containing multiple items. */
export interface ODataShippingLineDtoArrayODataCollectionResponse {
  '@odata.context': string
  /** @format int32 */
  '@odata.count'?: number | null
  value: ODataShippingLineDto[]
}

export interface ODataShippingUnitKindDto {
  /** @format uuid */
  id: string
  code: string
  description: string
  /** @format int32 */
  lengthInFeet?: number | null
  type: ShippingUnitKindType
}

/** Defines the response to an OData query, potentially containing multiple items. */
export interface ODataShippingUnitKindDtoArrayODataCollectionResponse {
  '@odata.context': string
  /** @format int32 */
  '@odata.count'?: number | null
  value: ODataShippingUnitKindDto[]
}

export interface ODataShippingUnitLocationDto {
  yardZone?: string | null
  block?: string | null
  slots?: string[]
}

export interface ODataSiteDto {
  name: string
  code: string
  shortName: string
  description: string
  isActive?: boolean
  /** @format double */
  longitude?: number | null
  /** @format double */
  latitude?: number | null
  address?: ODataAddressDto | null
  /** @format uuid */
  id: string
}

/** Defines the response to an OData query, potentially containing multiple items. */
export interface ODataSiteDtoArrayODataCollectionResponse {
  '@odata.context': string
  /** @format int32 */
  '@odata.count'?: number | null
  value: ODataSiteDto[]
}

export interface ODataSlotDto {
  /** @format uuid */
  id: string
  name: string
  /** @format int32 */
  row?: number | null
  /** @format int32 */
  depth?: number | null
  /** @format int32 */
  level?: number | null
  isLiftOnOff?: boolean
  isLoadingDock?: boolean
  isActive?: boolean
  isDefault?: boolean
  slotType: SlotType
  slotSize?: SlotSize | null
  /** @format uuid */
  blockId: string
  blockName: string
  /** @format uuid */
  yardZoneId: string
  yardZoneName: string
  siteCode: string
}

/** Defines the response to an OData query, potentially containing multiple items. */
export interface ODataSlotDtoArrayODataCollectionResponse {
  '@odata.context': string
  /** @format int32 */
  '@odata.count'?: number | null
  value: ODataSlotDto[]
}

export interface ODataSlotYardVisitDto {
  /** @format uuid */
  yardVisitId: string
  /** @format date-time */
  loadingUnloadingPlannedAt?: string | null
  /** @format date-time */
  loadedUnloadedAt?: string | null
  /** @format date-time */
  detentionDateCustomerKtn?: string | null
  /** @format date-time */
  detentionDateCustomerShippingLine?: string | null
  /** @format date-time */
  closingDate?: string | null
  shippingUnitNumber?: string | null
  shippingUnitKind: string
  orderId?: string | null
  pickUpAddress?: string | null
  isOnChassis: boolean
  orderType?: OperationalOrderType | null
  hasQueuedMoves: boolean
}

export interface ODataSwapEligibleContainerDto {
  yardOrderId: string
  number?: string | null
  locations?: string[]
  locationDetails?: ODataShippingUnitLocationDto | null
  /** @format date-time */
  detentionDateCustomerKtn?: string | null
  /** @format date-time */
  realClosingDate?: string | null
  kind: string
  adrClasses?: string[]
  ghsClasses?: string[]
  /** @format date-time */
  lastMoveExecutedAt?: string | null
}

/** Defines the response to an OData query, potentially containing multiple items. */
export interface ODataSwapEligibleContainerDtoArrayODataCollectionResponse {
  '@odata.context': string
  /** @format int32 */
  '@odata.count'?: number | null
  value: ODataSwapEligibleContainerDto[]
}

export interface ODataTransportCompanyDto {
  /** @format uuid */
  id: string
  code: string
}

/** Defines the response to an OData query, potentially containing multiple items. */
export interface ODataTransportCompanyDtoArrayODataCollectionResponse {
  '@odata.context': string
  /** @format int32 */
  '@odata.count'?: number | null
  value: ODataTransportCompanyDto[]
}

/** Defines a yard-task finish context, exactly as it is persisted. This is a type for troubleshooting and development and should not be used for production use-cases. */
export interface ODataYardTaskFinishContextDto {
  type: string
  /** @format uuid */
  taskId: string
  /** @format uuid */
  yardVisitId: string
  /** @format uuid */
  swappedYardVisitId?: string | null
  /** @format date-time */
  createdAt: string
  createdBy: string
  /** @format uuid */
  id: string
  /** @format date-time */
  lastModifiedAt: string
  lastModifiedBy: string
  /** @format byte */
  rowVersion: string
}

/** Defines the response to an OData query, potentially containing multiple items. */
export interface ODataYardTaskFinishContextDtoArrayODataCollectionResponse {
  '@odata.context': string
  /** @format int32 */
  '@odata.count'?: number | null
  value: ODataYardTaskFinishContextDto[]
}

/** Defines an external identifier for a yard-visit, exactly as it is persisted. This is a type for troubleshooting and development and should not be used for production use-cases. */
export interface ODataYardVisitExternalIdDto {
  externalIdentifier: string
  /** @format uuid */
  yardVisitId: string
  /** @format date-time */
  createdAt: string
  createdBy: string
  /** @format uuid */
  id: string
  /** @format date-time */
  lastModifiedAt: string
  lastModifiedBy: string
  /** @format byte */
  rowVersion: string
}

/** Defines the response to an OData query, potentially containing multiple items. */
export interface ODataYardVisitExternalIdDtoArrayODataCollectionResponse {
  '@odata.context': string
  /** @format int32 */
  '@odata.count'?: number | null
  value: ODataYardVisitExternalIdDto[]
}

export interface ODataYardZoneDto {
  /** @format uuid */
  id: string
  name: string
  yardZoneType: YardZoneType
  isLiftOnOff?: boolean
  /** @format uuid */
  siteId: string
  siteCode: string
  siteName: string
  /** @format int32 */
  blocksCount: number
  isActive: boolean
}

/** Defines the response to an OData query, potentially containing multiple items. */
export interface ODataYardZoneDtoArrayODataCollectionResponse {
  '@odata.context': string
  /** @format int32 */
  '@odata.count'?: number | null
  value: ODataYardZoneDto[]
}

export type OperationalOrderCreateDto = OperationalOrderUpsertDto & {
  operationalOrderType: OperationalOrderType
  /**
   * @format date-time
   * @example "2024-11-14T10:13:01.6493484"
   */
  loadingUnloadingPlannedAt?: string | null
}

export interface OperationalOrderDto {
  /** @format uuid */
  id: string
  customerCode: string
  customerMainReference: string
  customerReferences: string[]
  operationalOrderId: string
  operationalOrderParentId?: string | null
  operationalOrderType: OperationalOrderType
  /**
   * @format date-time
   * @example "2024-11-14T10:13:01.6493484"
   */
  loadingUnloadingPlannedAt?: string | null
  /**
   * @format date-time
   * @example "2024-11-14T10:13:01.6493484"
   */
  loadedUnloadedAt?: string | null
  cargos?: CargoDto[]
  isActive?: boolean
  isArchived?: boolean
  owner: string
  blockedProcesses?: BlockedProcessDto[]
}

export interface OperationalOrderProjection {
  id: string
  customerMainReference: string
  customerReferences?: string[]
  operationalOrderId?: string | null
  operationalOrderParentId?: string | null
  /** @format date-time */
  loadedUnloadedAt?: string | null
  isActive?: boolean
  owner: string
  blockedProcesses?: BlockedProcessProjection[]
}

export enum OperationalOrderType {
  Inbound = 'Inbound',
  Outbound = 'Outbound',
  Storage = 'Storage'
}

export type OperationalOrderUpdateDto = OperationalOrderUpsertDto

export interface OperationalOrderUpsertDto {
  customerCode: string
  customerMainReference: string
  customerReferences: string[]
  operationalOrderId: string
  operationalOrderParentId?: string | null
  cargos?: CargoUpsertDto[]
}

export interface OperatorCreateDto {
  firstName: string
  lastName: string
  pinCode: string
  handlerIds?: string[]
}

export interface OperatorDetailsDto {
  /** @format uuid */
  id: string
  firstName: string
  lastName: string
  username: string
  isActive: boolean
  handlers: OperatorHandlerDto[]
}

export interface OperatorHandlerDto {
  /** @format uuid */
  id: string
  name: string
  handlerType: HandlerType
}

export interface OperatorUpdateDto {
  firstName: string
  lastName: string
  handlerIds?: string[]
}

export type OutMoveDetailsDto = YardTaskDetailsDto & {
  fromLocation: ShippingUnitLocationDto
  transportCompany?: string | null
  licensePlateTruck?: string | null
  licensePlateTrailer?: string | null
  transportIdentificationNumber?: string | null
}

export interface OutMoveFinishResult {
  yardOrderId: string
  taskId: string
}

export interface ParkingBlockCreateDto {
  name: string
  prefix: string
  /** @format uuid */
  yardZoneId: string
  /** @format int32 */
  rowsFrom: number
  /** @format int32 */
  rowsTo: number
  /** @format int32 */
  depthFrom: number
  /** @format int32 */
  depthTo: number
  isLiftOnOff: boolean
}

export interface ParkingBlockUpdateDto {
  name: string
  prefix: string
  /** @format int32 */
  rowsTo: number
  /** @format int32 */
  depthTo: number
  isLiftOnOff: boolean
}

export interface PermissionsDto {
  viewUserContext?: boolean
  changeUserContext?: boolean
  viewSites?: boolean
  viewOperators?: boolean
  createOperator?: boolean
  editOperator?: boolean
  changePinCodeOperator?: boolean
  activateDeactivateOperator?: boolean
  viewHandlers?: boolean
  createHandler?: boolean
  editHandler?: boolean
  activateDeactivateHandler?: boolean
  viewShippingUnitKinds?: boolean
  createShippingUnitKind?: boolean
  editShippingUnitKind?: boolean
  viewYardSetup?: boolean
  viewYardZones?: boolean
  createYardZone?: boolean
  editYardZone?: boolean
  activateDeactivateYardZone?: boolean
  viewBlocks?: boolean
  createBlock?: boolean
  editBlock?: boolean
  activateDeactivateBlock?: boolean
  markUnmarkDefaultBlock?: boolean
  viewSlots?: boolean
  viewSlotDetails?: boolean
  editSlot?: boolean
  activateDeactivateSlot?: boolean
  markUnmarkDefaultSlot?: boolean
  resetSite?: boolean
  exportSite?: boolean
  importSite?: boolean
  viewCustomers?: boolean
  viewNotifications?: boolean
  createNotification?: boolean
  editNotification?: boolean
  copyNotification?: boolean
  viewRules?: boolean
  createRule?: boolean
  editRule?: boolean
  activateDeactivateRule?: boolean
  viewShippingUnits?: boolean
  viewShippingUnitDetails?: boolean
  createPersonalView?: boolean
  checkIn?: boolean
  undoCheckIn?: boolean
  checkOut?: boolean
  undoCheckOut?: boolean
  updateLocation?: boolean
  setAvailableForPickup?: boolean
  setUnavailableForPickup?: boolean
  planLoadingUnloading?: boolean
  finishLoadingUnloading?: boolean
  undoFinishLoadingUnloading?: boolean
  adjustSeals?: boolean
  reject?: boolean
  undoReject?: boolean
  block?: boolean
  unblock?: boolean
  viewTasks?: boolean
  viewTaskDetails?: boolean
  setUrgent?: boolean
  setNotUrgent?: boolean
  setQueued?: boolean
  setPlannedDequeue?: boolean
  finishTask?: boolean
  requeueFinishedUndo?: boolean
  cancelTask?: boolean
  editPlannedToLocation?: boolean
  editAlternativeToLocation?: boolean
  viewYardOverview?: boolean
  overruleOwnership?: boolean
  viewOperatorContext?: boolean
  changeOperatorContext?: boolean
  setDarkLightMode?: boolean
  resetViews?: boolean
  assignSlotGroupingSetFind?: boolean
  createShiftTask?: boolean
  backoffice?: boolean
}

export interface PreferredYardZoneDto {
  /** @format uuid */
  id: string
  name: string
}

export interface ProblemDetails {
  type?: string | null
  title?: string | null
  /** @format int32 */
  status?: number | null
  detail?: string | null
  instance?: string | null
}

export enum ProcessName {
  CheckIn = 'CheckIn',
  UndoCheckIn = 'UndoCheckIn',
  CheckOut = 'CheckOut',
  UndoCheckOut = 'UndoCheckOut',
  CreateYardOrder = 'CreateYardOrder',
  UpdateYardOrder = 'UpdateYardOrder',
  CancelYardOrder = 'CancelYardOrder',
  BlockYardOrder = 'BlockYardOrder',
  FinishLoadingUnloading = 'FinishLoadingUnloading',
  UndoFinishLoadingUnloading = 'UndoFinishLoadingUnloading',
  SetAvailableForPickup = 'SetAvailableForPickup',
  SetUnavailableForPickup = 'SetUnavailableForPickup',
  Reuse = 'Reuse',
  UndoReuse = 'UndoReuse',
  Unblock = 'Unblock',
  Reject = 'Reject',
  Return = 'Return',
  UndoReject = 'UndoReject',
  UpdateSeals = 'UpdateSeals',
  UpdateToLocation = 'UpdateToLocation',
  UpdateExpectedLocation = 'UpdateExpectedLocation',
  UpdateOperatorsPlannedToLocation = 'UpdateOperatorsPlannedToLocation',
  UpdatePlannedToLocation = 'UpdatePlannedToLocation',
  UpdateAlternativePlannedToLocation = 'UpdateAlternativePlannedToLocation',
  UpdateSite = 'UpdateSite',
  UpdateShippingUnitKind = 'UpdateShippingUnitKind',
  CreateShiftMove = 'CreateShiftMove',
  FinishInMove = 'FinishInMove',
  FinishShiftMove = 'FinishShiftMove',
  FinishOutMove = 'FinishOutMove',
  RequeueYardTask = 'RequeueYardTask',
  ResetToPlanned = 'ResetToPlanned',
  CancelYardTask = 'CancelYardTask',
  FinishQuickShiftMove = 'FinishQuickShiftMove',
  ArdProcessVisit = 'ArdProcessVisit',
  RulesEvaluation = 'RulesEvaluation',
  UpdateLoadingUnloadingPlannedAt = 'UpdateLoadingUnloadingPlannedAt'
}

export interface PushNotificationDto {
  notificationType?: PushNotificationType
  data?: Record<string, any>
}

export enum PushNotificationType {
  GenericNotification = 'GenericNotification',
  YardTaskChanged = 'YardTaskChanged',
  YardOrderChanged = 'YardOrderChanged',
  OperatorDeactivated = 'OperatorDeactivated'
}

export enum QueueMoveAfter {
  DateTime = 'DateTime',
  PlannedOperationDate = 'PlannedOperationDate',
  CheckIn = 'CheckIn',
  CheckOut = 'CheckOut'
}

export interface QuickShiftDto {
  /** The slot to which the shipping unit should be shifted. */
  slot: string
}

export interface RejectDto {
  /** The reason why a container can not be loaded or unloaded */
  reason?: string
}

export enum RelationType {
  Inbound = 'Inbound',
  Outbound = 'Outbound'
}

export interface ResetSiteDataDto {
  removeYardSetup: boolean
}

export interface ReturnShippingUnitDto {
  siteCode: string
  shippingUnitNumber: string
  operationalOrder: OperationalOrderCreateDto
}

export interface ReuseDto {
  shippingUnitNumberToReuse: string
  operationalOrderIdOfShippingUnitToReuse?: string | null
  operationalOrderIdForWhichReuse: string
}

export enum RoleType {
  Owner = 'Owner',
  Administrator = 'Administrator',
  User = 'User',
  Operator = 'Operator',
  Viewer = 'Viewer'
}

export interface RowsPairDto {
  /** @format int32 */
  row1: number
  /** @format int32 */
  row2: number
}

export interface RuleActionDto {
  type: ActionType
  value?: string | null
}

export interface RuleConditionDto {
  type: ConditionType
  values?: RuleConditionValueDto[]
}

export interface RuleConditionProjection {
  type: string
  values: string[]
}

export interface RuleConditionValueDto {
  value: string
}

export interface RuleDto {
  /** @format uuid */
  id: string
  type: RuleType
  isActive?: boolean
  conditions?: RuleConditionDto[]
  triggers?: RuleTriggerDto[]
  actions?: RuleActionDto[]
}

export interface RuleProjection {
  id: string
  type: string
  isActive: boolean
  triggers: TriggerProjection[]
  siteConditions: RuleConditionProjection[]
  shippingUnitLocationConditions: RuleConditionProjection[]
  actions: ActionProjection[]
}

export interface RuleProjectionIEnumerableAzureAISearchCollectionResponse {
  '@search.facets'?: Record<string, AzureAISearchFacet[]>
  '@odata.context': string
  /** @format int32 */
  '@odata.count'?: number | null
  value: RuleProjection[]
}

export interface RuleTriggerDto {
  type: TriggerType
  value?: string | null
}

export enum RuleType {
  AutoCreateShiftMove = 'AutoCreateShiftMove'
}

export interface ScreenDataFieldRequestModel {
  /** @format int32 */
  number?: number
  searchText?: string | null
}

export interface ScreenValidationFieldRequestModel {
  /** @format int32 */
  fieldNumber?: number
  fieldValue?: string
  /** @format int32 */
  screenNumber?: number
}

export interface ScreenValidationFieldResponseModel {
  /** @format int32 */
  fieldNumber?: number
  message?: string | null
}

export interface ScreenValidationRequestModel {
  ardSiteCode?: string
  applicationTransportEntryNumber?: string
  ardName?: string
  containerAction?: string
  containerNumber?: string
  fields?: ScreenValidationFieldRequestModel[]
  flowStatus?: FlowStatus
  isContainer?: boolean
  languageCode?: string
  printerName?: string
  relationType?: RelationType
  webServiceUrl?: string
  metadata?: Record<string, string>
  announcementType?: string | null
}

export interface ScreenValidationResponseModel {
  applicationTransportEntryNumber?: string | null
  fields?: ScreenValidationFieldResponseModel[]
  message?: string | null
  relationType?: RelationType | null
  result?: ValidationResponseStatus
  updatedOrderProperties?: ScreenValidationUpdateOrderPropertyModel[]
}

export interface ScreenValidationUpdateOrderPropertyModel {
  property?: string | null
  value?: string | null
}

export interface ScreenValuesFieldResponseModel {
  name?: string
  /** @format int32 */
  number?: number
  values?: ScreenValuesFieldValueResponseModel[]
}

export interface ScreenValuesFieldValueResponseModel {
  value?: string
  description?: string | null
  label?: string | null
  isSelected?: boolean
  isReadOnly?: boolean
  imageValue?: string | null
}

export interface ScreenValuesRequestModel {
  action?: string | null
  applicationTransportEntryNumber?: string | null
  ardName?: string
  ardSiteCode?: string
  fields?: ScreenDataFieldRequestModel[]
  languageCode?: string
  relationType?: RelationType | null
  /** @format int32 */
  screenNumber?: number
  webServiceUrl?: string
  metadata?: Record<string, string>
  /** @format uuid */
  companyId?: string
  announcementType?: string | null
}

export interface ScreenValuesResponseModel {
  message?: string | null
  fields?: ScreenValuesFieldResponseModel[]
  result?: ValidationResponseStatus
}

export interface SessionDto {
  /** @format uuid */
  handlerId: string
  yardZoneIds: string[]
}

export interface SetPinCodeDto {
  newPinCode: string
  confirmNewPinCode: string
}

export interface SetUserPreferenceDto {
  sites?: SetUserPreferredSiteDto[]
}

export interface SetUserPreferredSiteDto {
  /** @format uuid */
  siteId: string
  yardZoneIds?: string[]
}

export type ShiftMoveDetailsDto = YardTaskDetailsDto & {
  /**
   * @format date-time
   * @example "2024-11-14T10:13:01.6493484"
   */
  finishBefore?: string | null
  fromLocation: ShippingUnitLocationDto
  plannedToLocation: YardLocationDto
  alternativePlannedToLocation?: YardLocationDto | null
  actualLocation?: ShippingUnitLocationDto | null
}

export type ShippingUnitCreateDto = ShippingUnitUpsertDto & {
  location?: string | null
}

export interface ShippingUnitKindDetailsDto {
  /** @format uuid */
  id: string
  code: string
  description: string
  type: ShippingUnitKindType
  /** @format int32 */
  lengthInFeet?: number | null
}

export enum ShippingUnitKindType {
  Other = 'Other',
  PackedContainer = 'PackedContainer',
  BulkTrailer = 'BulkTrailer',
  PalletTrailer = 'PalletTrailer',
  Chassis = 'Chassis',
  Railcar = 'Railcar',
  Hoppercar = 'Hoppercar',
  Vanbox = 'Vanbox',
  TankContainer = 'TankContainer',
  BulkContainer = 'BulkContainer'
}

export interface ShippingUnitKindUpsertDto {
  code: string
  description: string
  type: ShippingUnitKindType
  /** @format int32 */
  lengthInFeet?: number | null
}

export interface ShippingUnitLocationDto {
  yardZone?: string | null
  block?: string | null
  slots?: string[]
}

export interface ShippingUnitLocationProjection {
  yardZone?: string | null
  block?: string | null
  slots?: string[]
}

export enum ShippingUnitStatus {
  Expected = 'Expected',
  CheckedIn = 'CheckedIn',
  In = 'In',
  CheckedOut = 'CheckedOut',
  Out = 'Out',
  Cancelled = 'Cancelled'
}

export type ShippingUnitUpdateDto = ShippingUnitUpsertDto

export interface ShippingUnitUpsertDto {
  kind: string
  number?: string | null
  contentStatus: ContentStatus
  shippingLine?: string | null
}

export interface SiloBlockCreateDto {
  name: string
  prefix: string
  /** @format uuid */
  yardZoneId: string
  /** @format int32 */
  rowsFrom: number
  /** @format int32 */
  rowsTo: number
  /** @format int32 */
  depthFrom: number
  /** @format int32 */
  depthTo: number
  isLiftOnOff: boolean
}

export interface SiloBlockUpdateDto {
  name: string
  prefix: string
  /** @format int32 */
  rowsTo: number
  /** @format int32 */
  depthTo: number
  isLiftOnOff: boolean
}

export interface SiteDto {
  /** @format uuid */
  id: string
  name: string
  code: string
  yardZones: YardZoneDto[]
}

export enum SiteFacet {
  Code = 'Code',
  Name = 'Name'
}

export enum SlotSize {
  TwentyFeet = 'TwentyFeet',
  FortyFeet = 'FortyFeet'
}

export enum SlotStatus {
  Free = 'Free',
  PartiallyOccupied = 'PartiallyOccupied',
  Occupied = 'Occupied'
}

export enum SlotType {
  ContainerStack = 'ContainerStack',
  Parking = 'Parking',
  FillingPipe = 'FillingPipe',
  Gate = 'Gate',
  Silo = 'Silo'
}

export interface SlotUpdateDto {
  isLiftOnOff: boolean
}

export interface SlotUpdateResponseDto {
  /** @format uuid */
  id: string
  name: string
  /** @format int32 */
  row?: number | null
  /** @format int32 */
  depth?: number | null
  /** @format int32 */
  level?: number | null
  isLiftOnOff?: boolean
  isLoadingDock?: boolean
  isActive?: boolean
  slotType: SlotType
  slotSize?: SlotSize | null
  /** @format uuid */
  blockId: string
}

export interface SmsDto {
  targetPhoneNumber: string
  text: string
}

export interface SwapAllowanceEvaluationDetailsDto {
  criterionName: string
  values: string[]
  criterionIsMet?: boolean
}

export enum TransportStatus {
  Requested = 'Requested',
  Accepted = 'Accepted',
  Arrived = 'Arrived',
  AvailableForPickUp = 'AvailableForPickUp',
  BusyDelivery = 'BusyDelivery',
  BusyPickUp = 'BusyPickUp',
  Cancelled = 'Cancelled',
  Finished = 'Finished',
  PlannedDelivery = 'PlannedDelivery',
  PlannedPickUp = 'PlannedPickUp',
  OnHold = 'OnHold'
}

export interface TriggerProjection {
  type: string
  value?: string | null
}

export enum TriggerType {
  ContentStatusChanged = 'ContentStatusChanged'
}

export interface UpdateExpectedLocationDto {
  location: string
}

export interface UpdateLoadingUnloadingPlannedAtDto {
  /**
   * @format date-time
   * @example "2024-11-14T10:13:01.6493484"
   */
  loadingUnloadingPlannedAt: string
}

export interface UpdateSealsDto {
  incomingSeals: string[]
  outgoingSeals: string[]
}

export interface UpdateSiteDto {
  siteCode: string
  plannedLocation?: string | null
}

export interface UpdateToLocationDto {
  location: string
}

export interface UserPreferenceDetailsDto {
  sites: UserPreferredSiteDto[]
}

export interface UserPreferredSiteDto {
  /** @format uuid */
  id: string
  code: string
  yardZones?: PreferredYardZoneDto[]
}

export enum ValidationResponseStatus {
  ValidContinueFlow = 'ValidContinueFlow',
  InvalidTryAgain = 'InvalidTryAgain',
  InvalidEndFlow = 'InvalidEndFlow',
  ValidSkipScreen = 'ValidSkipScreen',
  ValidUseUpdatedOrder = 'ValidUseUpdatedOrder',
  Error = 'Error'
}

export interface VisitValidationInstructionResponseModel {
  content?: string
  /** @format int32 */
  sequence?: number
  title?: string
  type?: string
  waypoints?: VisitValidationInstructionWaypointResponseModel[]
}

export interface VisitValidationInstructionWaypointResponseModel {
  identifier?: string
  /** @format int32 */
  sequence?: number
}

export interface VisitValidationOrderFieldRequestModel {
  /** @format int32 */
  fieldNumber?: number
  fieldValue?: string
  /** @format int32 */
  screenNumber?: number
}

export interface VisitValidationOrderRequestModel {
  applicationTransportEntryNumber?: string
  containerAction?: string
  fields?: VisitValidationOrderFieldRequestModel[]
  isContainer?: boolean
  relationType?: RelationType
  containerNumber?: string
}

export interface VisitValidationOrderResponseModel {
  applicationTransportEntryNumber?: string
  instructions?: VisitValidationInstructionResponseModel[]
  message?: string
  relationType?: RelationType
  result?: ValidationResponseStatus
}

export interface VisitValidationRequestModel {
  ardName?: string
  flowStatus?: string
  languageCode?: string
  orders?: VisitValidationOrderRequestModel[]
  printerName?: string
  ardSiteCode?: string
  announcementType?: string | null
}

export interface VisitValidationResponseModel {
  errorMessage?: string
  instructions?: VisitValidationInstructionResponseModel[]
  orders?: VisitValidationOrderResponseModel[]
  metadata?: Record<string, string>
  result?: ValidationResponseStatus
}

export interface YardLocationDto {
  yardZone?: string | null
  block?: string | null
  slot?: string | null
}

export interface YardLocationProjection {
  yardZone?: string | null
  block?: string | null
  slot?: string | null
}

export interface YardOrderCargoProjection {
  article?: string | null
  product?: string | null
  lotBatch?: string | null
  configuration?: string | null
  warehouseCode?: string | null
  gateCode?: string | null
  adrClasses?: string[]
  ghsClasses?: string[]
}

export interface YardOrderCreateDto {
  siteCode: string
  driverWaits?: boolean
  /**
   * @format date-time
   * @example "2024-11-14T10:13:01.6493484"
   */
  estimatedTimeArrival?: string | null
  vessel?: string | null
  pickupAddress?: string | null
  returnAddress?: string | null
  returnReference?: string | null
  alternativeReturnAddress?: string | null
  alternativeReturnReference?: string | null
  /**
   * @format date
   * @example "2024-11-14"
   */
  detentionDateCustomerKtn?: string | null
  /**
   * @format date
   * @example "2024-11-14"
   */
  detentionDateCustomerShippingLine?: string | null
  /**
   * @format date-time
   * @example "2024-11-14T10:13:01.6493484"
   */
  amsClosingDate?: string | null
  /**
   * @format date-time
   * @example "2024-11-14T10:13:01.6493484"
   */
  vgmClosingDate?: string | null
  /**
   * @format date-time
   * @example "2024-11-14T10:13:01.6493484"
   */
  closingDate?: string | null
  /**
   * @format date
   * @example "2024-11-14"
   */
  freeFullInFromDate?: string | null
  shippingUnit: ShippingUnitCreateDto
  expectedCheckIn?: CheckInUpsertDto | null
  expectedCheckOut?: CheckOutUpsertDto | null
  operationalOrder: OperationalOrderCreateDto
  transportStatus?: TransportStatus | null
  incomingSeals?: string[]
  outgoingSeals?: string[]
}

export interface YardOrderDetailsDto {
  id: string
  shippingUnitStatus: ShippingUnitStatus
  number?: string | null
  contentStatus: ContentStatus
  kind: string
  plannedLocation?: string | null
  locations?: string[]
  /**
   * @format date-time
   * @example "2024-11-14T10:13:01.6493484"
   */
  estimatedTimeArrival?: string | null
  siteCode: string
  shippingLine?: string | null
  vessel?: string | null
  returnAddress?: string | null
  alternativeReturnAddress?: string | null
  returnReference?: string | null
  alternativeReturnReference?: string | null
  /**
   * @format date
   * @example "2024-11-14"
   */
  detentionDateCustomerKtn?: string | null
  /**
   * @format date
   * @example "2024-11-14"
   */
  detentionDateCustomerShippingLine?: string | null
  /**
   * @format date-time
   * @example "2024-11-14T10:13:01.6493484"
   */
  amsClosingDate?: string | null
  /**
   * @format date-time
   * @example "2024-11-14T10:13:01.6493484"
   */
  vgmClosingDate?: string | null
  /**
   * @format date-time
   * @example "2024-11-14T10:13:01.6493484"
   */
  closingDate?: string | null
  /**
   * @format date
   * @example "2024-11-14"
   */
  freeFullInFromDate?: string | null
  /**
   * @format date
   * @example "2024-11-14"
   */
  pickedUpAtTerminalDate?: string | null
  operationalOrders?: OperationalOrderDto[]
  yardZone?: string | null
  block?: string | null
  slots?: string[]
  driverWaits?: boolean
  isAvailableForPickup?: boolean
  isLiftOnOff?: boolean
  isOnChassis?: boolean
  tasks?: YardTaskDto[]
  pickupAddress?: string | null
  containsDangerousGoods?: boolean
  checkIn?: CheckInDetailsDto | null
  checkOut?: CheckOutDetailsDto | null
  isRejected?: boolean
  rejectReason?: string | null
  /**
   * @format date-time
   * @example "2024-11-14T10:13:01.6493484"
   */
  inAt?: string | null
  /**
   * @format date-time
   * @example "2024-11-14T10:13:01.6493484"
   */
  outAt?: string | null
  isReused?: boolean
  transportStatus?: TransportStatus | null
  isSwapAllowed?: boolean
  swapAllowanceEvaluationDetails?: SwapAllowanceEvaluationDetailsDto[]
  transportCompanyAllowsSwap?: boolean
  actionBlocks?: ActionBlockDto[]
  incomingSeals?: string[]
  outgoingSeals?: string[]
  internalProcessingErrors?: InternalProcessingErrorDto[]
}

export interface YardOrderProjection {
  id: string
  shippingUnitId: string
  status?: string | null
  number?: string | null
  contentStatus?: string | null
  kind?: string | null
  meansOfTransportIn?: string | null
  meansOfTransportOut?: string | null
  locations?: string[]
  /** @format date-time */
  estimatedTimeArrival?: string | null
  /** @format int32 */
  numberOfOpenTasks?: number
  siteCode?: string | null
  shippingLine?: string | null
  vessel?: string | null
  returnAddress?: string | null
  alternativeReturnAddress?: string | null
  returnReference?: string | null
  alternativeReturnReference?: string | null
  /** @format date-time */
  detentionDateCustomerKtn?: string | null
  /** @format date-time */
  detentionDateCustomerShippingLine?: string | null
  /** @format date-time */
  closingOrDetentionDate?: string | null
  /** @format date-time */
  amsClosingDate?: string | null
  /** @format date-time */
  vgmClosingDate?: string | null
  /** @format date-time */
  closingDate?: string | null
  /** @format date-time */
  freeFullInFromDate?: string | null
  customerCode?: string | null
  customerMainReference: string
  customerReferences?: string[]
  operationalOrderId?: string | null
  operationalOrderParentId?: string | null
  operationalOrderType?: string | null
  /** @format date-time */
  loadingUnloadingPlannedAt?: string | null
  cargos?: YardOrderCargoProjection[]
  operationalOrders?: OperationalOrderProjection[]
  yardZone?: string | null
  block?: string | null
  slots?: string[]
  driverWaits?: boolean
  isAvailableForPickup?: boolean
  isRejected?: boolean
  rejectReason?: string | null
  isOnChassis?: boolean
  isLiftOnOff?: boolean
  parentOrderGroup?: string | null
  isReused?: boolean
  isBlocked?: boolean
  actionBlocks?: ActionBlockProjection[]
  transportStatus?: string | null
  /** @format date-time */
  inAt?: string | null
  incomingSeals?: string[]
  outgoingSeals?: string[]
  hasIncomingSeals?: boolean
  hasOutgoingSeals?: boolean
}

export interface YardOrderProjectionIEnumerableAzureAISearchCollectionResponse {
  '@search.facets'?: Record<string, AzureAISearchFacet[]>
  '@odata.context': string
  /** @format int32 */
  '@odata.count'?: number | null
  value: YardOrderProjection[]
}

export interface YardOrderUpdateDto {
  driverWaits?: boolean
  /**
   * @format date-time
   * @example "2024-11-14T10:13:01.6493484"
   */
  estimatedTimeArrival?: string | null
  vessel?: string | null
  pickupAddress?: string | null
  returnAddress?: string | null
  returnReference?: string | null
  alternativeReturnAddress?: string | null
  alternativeReturnReference?: string | null
  shippingUnit: ShippingUnitUpdateDto
  /**
   * @format date
   * @example "2024-11-14"
   */
  detentionDateCustomerKtn?: string | null
  /**
   * @format date
   * @example "2024-11-14"
   */
  detentionDateCustomerShippingLine?: string | null
  /**
   * @format date-time
   * @example "2024-11-14T10:13:01.6493484"
   */
  amsClosingDate?: string | null
  /**
   * @format date-time
   * @example "2024-11-14T10:13:01.6493484"
   */
  vgmClosingDate?: string | null
  /**
   * @format date-time
   * @example "2024-11-14T10:13:01.6493484"
   */
  closingDate?: string | null
  /**
   * @format date
   * @example "2024-11-14"
   */
  freeFullInFromDate?: string | null
  expectedCheckIn?: CheckInUpsertDto | null
  expectedCheckOut?: CheckOutUpsertDto | null
  operationalOrder: OperationalOrderUpdateDto
  transportStatus?: TransportStatus | null
  transportCompanyAllowSwap?: boolean
}

export interface YardTaskCargoProjection {
  article?: string | null
  product?: string | null
  lotBatch?: string | null
  configuration?: string | null
  warehouseCode?: string | null
  gateCode?: string | null
  adrClasses?: string[]
  ghsClasses?: string[]
}

export interface YardTaskDetailsDto {
  id: string
  yardOrderId: string
  shippingUnit?: string | null
  shippingUnitStatus: ShippingUnitStatus
  isUrgent?: boolean
  remark?: string | null
  taskType: YardTaskType
  moveType: MoveType
  handlerType: HandlerType
  siteCode: string
  queueMoveAfter: QueueMoveAfter
  /**
   * @format date-time
   * @example "2024-11-14T10:13:01.6493484"
   */
  queueOn?: string | null
  /**
   * @format date-time
   * @example "2024-11-14T10:13:01.6493484"
   */
  finishedOn?: string | null
  finishedBy?: ExecutedByDto | null
  finishingRemark?: string | null
  meansOfTransport?: MeansOfTransport | null
  status: YardTaskStatus
  queueErrors?: string[]
  cancelReason?: string | null
}

export interface YardTaskDto {
  id: string
  status: YardTaskStatus
  handlerType: HandlerType
  queueMoveAfter: QueueMoveAfter
  /**
   * @format date-time
   * @example "2024-11-14T10:13:01.6493484"
   */
  queuedOn?: string | null
  /**
   * @format date-time
   * @example "2024-11-14T10:13:01.6493484"
   */
  finishBefore?: string | null
  /**
   * @format date-time
   * @example "2024-11-14T10:13:01.6493484"
   */
  finishedOn?: string | null
  finishedBy?: ExecutedByDto | null
  finishingRemark?: string | null
  isUrgent?: boolean
  taskType: YardTaskType
  moveType?: MoveType | null
  licensePlateTruck?: string | null
  licensePlateTrailer?: string | null
  remark?: string | null
  fromLocations?: string[]
  plannedLocation?: string | null
  plannedLocationDetails?: YardLocationDto | null
  alternativePlannedLocation?: string | null
  alternativePlannedLocationDetails?: YardLocationDto | null
  actualLocation?: string[]
  operatorsPlannedLocation?: string | null
  operatorsPlannedLocationDetails?: YardLocationDto | null
  meansOfTransport?: MeansOfTransport | null
  transportCompany?: string | null
  transportIdentificationNumber?: string | null
  queueErrors?: string[]
  cancelReason?: string | null
}

export interface YardTaskProjection {
  id: string
  yardOrderId: string
  siteCode?: string | null
  shippingUnitNumber?: string | null
  shippingUnitKind?: string | null
  shippingUnitStatus?: string | null
  status?: string | null
  shippingUnitContentStatus?: string | null
  type?: string | null
  moveType?: string | null
  handlerType?: string | null
  fromLocations?: string[]
  fromLocationDetails?: ShippingUnitLocationProjection | null
  plannedToLocation?: string | null
  plannedToLocationDetails?: YardLocationProjection | null
  alternativePlannedToLocation?: string | null
  alternativePlannedToLocationDetails?: YardLocationProjection | null
  operatorsPlannedToLocation?: string | null
  operatorsPlannedToLocationDetails?: YardLocationProjection | null
  actualToLocations?: string[]
  actualToLocationDetails?: ShippingUnitLocationProjection | null
  finalToLocation?: string | null
  finalToLocationDetails?: YardLocationProjection | null
  isUrgent?: boolean | null
  customerCode?: string | null
  transportCompany?: string | null
  licensePlateTruck?: string | null
  licensePlateTrailer?: string | null
  /** @format date-time */
  queuedOn?: string | null
  meansOfTransport?: string | null
  cargos?: YardTaskCargoProjection[]
  /** @format date-time */
  finishBefore?: string | null
  createdBy?: string | null
  /** @format date-time */
  createdAt?: string | null
  queuedBy?: string | null
  finishedBy?: string | null
  /** @format date-time */
  finishedOn?: string | null
  finishingRemark?: string | null
  remark?: string | null
  operationalOrderIdentifier?: string | null
  operationalOrderParentIdentifier?: string | null
  customerReferences?: string | null
  customerMainReference: string
  queueErrors?: string[]
  hasQueueErrors?: boolean
  parentOrderGroup?: string | null
  /** @format date-time */
  checkedInOutTime?: string | null
  vessel?: string | null
  /** @format date-time */
  detentionDateCustomerKtn?: string | null
  /** @format date-time */
  detentionDateCustomerShippingLine?: string | null
  /** @format date-time */
  closingDate?: string | null
  /** @format date-time */
  closingOrDetentionDate?: string | null
  /** @format date-time */
  loadingUnloadingPlannedAt?: string | null
  isLoadingUnloadingFinished?: boolean | null
  operationalOrderType?: string | null
  shippingLine?: string | null
  returnAddress?: string | null
  alternativeReturnAddress?: string | null
  isSwapAllowed?: boolean
  isRequeueAllowed?: boolean
  shippingUnitIsRejected?: boolean
  shippingUnitRejectReason?: string | null
}

export interface YardTaskProjectionIEnumerableAzureAISearchCollectionResponse {
  '@search.facets'?: Record<string, AzureAISearchFacet[]>
  '@odata.context': string
  /** @format int32 */
  '@odata.count'?: number | null
  value: YardTaskProjection[]
}

export enum YardTaskStatus {
  Planned = 'Planned',
  Queued = 'Queued',
  Busy = 'Busy',
  Finished = 'Finished',
  Cancelled = 'Cancelled'
}

export enum YardTaskType {
  Move = 'Move',
  RepositionShippingUnit = 'RepositionShippingUnit',
  PressWaste = 'PressWaste',
  TurnContainerOnChassis = 'TurnContainerOnChassis',
  PlugInReeferContainer = 'PlugInReeferContainer',
  WeighShippingUnit = 'WeighShippingUnit',
  AddAirToTrailer = 'AddAirToTrailer',
  OpenDoorsOfShippingUnit = 'OpenDoorsOfShippingUnit',
  HeatContainer = 'HeatContainer'
}

export interface YardTaskUpdateDto {
  remark: string
}

export interface YardZoneCreateDto {
  name: string
  yardZoneType: YardZoneType
  isLiftOnOff: boolean
  /** @format uuid */
  siteId: string
}

export interface YardZoneDetailsDto {
  /** @format uuid */
  id: string
  name: string
  yardZoneType: YardZoneType
  isLiftOnOff: boolean
  /** @format uuid */
  siteId: string
  isActive: boolean
}

export interface YardZoneDto {
  /** @format uuid */
  id: string
  name: string
}

export enum YardZoneType {
  ContainerStack = 'ContainerStack',
  Parking = 'Parking',
  SiloPack = 'SiloPack',
  Warehouse = 'Warehouse',
  Railyard = 'Railyard'
}

export interface YardZoneUpdateDto {
  name: string
  yardZoneType: YardZoneType
  isLiftOnOff: boolean
}

export type GetScreenValuesPayload = ScreenValuesRequestModel

export type CheckScreenPayload = ScreenValidationRequestModel

export type ProcessVisitPayload = VisitValidationRequestModel

export type InitializeSitesPayload = InitializeSitesParams

export type InitializeCountriesPayload = InitializeCountriesParams

export interface GetYardVisitExternalIdsParams {
  select?: string
  expand?: string
  filter?: string
  orderby?: string
  /** @format int32 */
  top?: number
  /** @format int32 */
  skip?: number
  count?: boolean
}

export interface GetYardTaskFinishContextsParams {
  select?: string
  expand?: string
  filter?: string
  orderby?: string
  /** @format int32 */
  top?: number
  /** @format int32 */
  skip?: number
  count?: boolean
}

export interface RebuildOperationalLocationsAggregateParams {
  /**
   * Size of cosmos db operation batch related to amount of delete and create operations. Setting it to high my cause container's throughput to be exhausted. Currently, works fine below 300.
   * @format int32
   */
  operationBatchSize?: number
}

export type MailApiSendMailPayload = MailDto

export type SmsApiSendSmsPayload = SmsDto

export interface SendHistoryServiceTestMessageParams {
  /** @format uuid */
  eventClusterId?: string
}

export interface SeedTestDataPayload {
  /** @format binary */
  file?: File
}

export type UpdateContainerStackBlockPayload = ContainerStackBlockUpdateDto

export type UpdateSiloBlockPayload = SiloBlockUpdateDto

export type UpdateGateBlockPayload = GateBlockUpdateDto

export type UpdateParkingBlockPayload = ParkingBlockUpdateDto

export type UpdateFillingPipeBlockPayload = FillingPipeBlockUpdateDto

export type SetDefaultSlotPayload = DefaultSlotDto

export type ClearDefaultSlotPayload = DefaultSlotDto

export interface GetODataBlocksParams {
  select?: string
  expand?: string
  filter?: string
  orderby?: string
  /** @format int32 */
  top?: number
  /** @format int32 */
  skip?: number
  count?: boolean
}

/** DTO with all the properties required to create a block */
export type CreateContainerStackBlockPayload = ContainerStackBlockCreateDto

export type CreateSiloBlockPayload = SiloBlockCreateDto

export type CreateGateBlockPayload = GateBlockCreateDto

export type CreateParkingBlockPayload = ParkingBlockCreateDto

export type CreateFillingPipeBlockPayload = FillingPipeBlockCreateDto

export interface GetODataCustomersParams {
  select?: string
  expand?: string
  filter?: string
  orderby?: string
  /** @format int32 */
  top?: number
  /** @format int32 */
  skip?: number
  count?: boolean
}

export interface GetFeatureFlagsParams {
  name?: string
}

export type UpdateHandlerPayload = HandlerUpsertDto

export interface GetODataHandlersParams {
  select?: string
  expand?: string
  filter?: string
  orderby?: string
  /** @format int32 */
  top?: number
  /** @format int32 */
  skip?: number
  count?: boolean
}

export type CreateHandlerPayload = HandlerUpsertDto

/** The DTO used to create or update a subscription to a particular notification. */
export type UpdateSubscriptionPayload = NotificationSubscriptionUpsertDto

/** The DTO used to create or update a subscription to a particular notification. */
export type CreateSubscriptionPayload = NotificationSubscriptionUpsertDto

export interface GetOperationalBlocksODataParams {
  select?: string
  expand?: string
  filter?: string
  orderby?: string
  /** @format int32 */
  top?: number
  /** @format int32 */
  skip?: number
  count?: boolean
}

export interface GetOperationalSitesODataParams {
  select?: string
  expand?: string
  filter?: string
  orderby?: string
  /** @format int32 */
  top?: number
  /** @format int32 */
  skip?: number
  count?: boolean
}

export interface GetOperationalSlotsODataParams {
  select?: string
  expand?: string
  filter?: string
  orderby?: string
  /** @format int32 */
  top?: number
  /** @format int32 */
  skip?: number
  count?: boolean
}

export interface GetOperationalYardZonesODataParams {
  select?: string
  expand?: string
  filter?: string
  orderby?: string
  /** @format int32 */
  top?: number
  /** @format int32 */
  skip?: number
  count?: boolean
}

export type UpsertOperatorViewPayload = string

export type UpdateOperatorPayload = OperatorUpdateDto

export type SetPinCodePayload = SetPinCodeDto

export type SaveSessionPayload = SessionDto

export interface GetODataOperatorsParams {
  select?: string
  expand?: string
  filter?: string
  orderby?: string
  /** @format int32 */
  top?: number
  /** @format int32 */
  skip?: number
  count?: boolean
}

export type CreateOperatorPayload = OperatorCreateDto

export type SubscribePayload = CreatePushNotificationSubscriptionDto

export type SendNotificationPayload = PushNotificationDto

export type UpdateAutoShiftMoveRulePayload = AutoShiftMoveRuleUpsertDto

export type CreateAutoShiftMoveRulePayload = AutoShiftMoveRuleUpsertDto

export interface GetODataShippingLinesParams {
  select?: string
  expand?: string
  filter?: string
  orderby?: string
  /** @format int32 */
  top?: number
  /** @format int32 */
  skip?: number
  count?: boolean
}

export type UpdateShippingUnitKindPayload = ShippingUnitKindUpsertDto

export interface GetODataShippingUnitKindsParams {
  select?: string
  expand?: string
  filter?: string
  orderby?: string
  /** @format int32 */
  top?: number
  /** @format int32 */
  skip?: number
  count?: boolean
}

export type CreateShippingUnitKindPayload = ShippingUnitKindUpsertDto

export type ResetSiteDataPayload = ResetSiteDataDto

export interface GetODataSitesParams {
  select?: string
  expand?: string
  filter?: string
  orderby?: string
  /** @format int32 */
  top?: number
  /** @format int32 */
  skip?: number
  count?: boolean
}

export interface GetSiteFacetParams {
  facetType?: SiteFacet
  search?: string
  dependsOnSiteCode?: string[]
  dependsOnSiteName?: string[]
}

export type UpdateSlotPayload = SlotUpdateDto

export interface GetODataParams {
  select?: string
  expand?: string
  filter?: string
  orderby?: string
  /** @format int32 */
  top?: number
  /** @format int32 */
  skip?: number
  count?: boolean
}

export interface GetODataTransportCompaniesParams {
  select?: string
  expand?: string
  filter?: string
  orderby?: string
  /** @format int32 */
  top?: number
  /** @format int32 */
  skip?: number
  count?: boolean
}

export type UpsertUserViewPayload = string

export type SetUserPreferencePayload = SetUserPreferenceDto

export type UpdateYardOrderPayload = YardOrderUpdateDto

export type UpdateToLocationPayload = UpdateToLocationDto

export type UpdateSitePayload = UpdateSiteDto

export type UpdateSealsPayload = UpdateSealsDto

export type UpdateLoadingUnloadingPlannedAtPayload = UpdateLoadingUnloadingPlannedAtDto

export type UpdateExpectedLocationPayload = UpdateExpectedLocationDto

export interface GetSwapEligibleContainersParams {
  /** The ID of the yard order with the container that potentially could be swapped. */
  yardOrderId: string
  /** The Microsoft.AspNetCore.OData.Query.ODataQueryOptions instance containing filters, ordering etc. settings. This value will be automatically provided by the runtime. */
  queryOptions?: any
  select?: string
  expand?: string
  filter?: string
  orderby?: string
  /** @format int32 */
  top?: number
  /** @format int32 */
  skip?: number
  count?: boolean
}

export type ReuseShippingUnitPayload = ReuseDto

export type ReturnPayload = ReturnShippingUnitDto

export type RejectPayload = RejectDto

export type FinishLoadingUnloadingPayload = FinishLoadingUnloadingDto

export type CreateYardOrderPayload = YardOrderCreateDto

export type CheckOutPayload = CheckOutBargeDto | CheckOutRailDto | CheckOutTruckDto

export type CheckInPayload = CheckInBargeDto | CheckInRailDto | CheckInTruckDto

export type BlockYardOrderPayload = BlockYardOrderDto

export type UpdateYardTaskPayload = YardTaskUpdateDto

/**
 * @format date-time
 * @example "2024-11-14T10:13:01.6493484"
 */
export type UpdateQueueOnPayload = string

export type UpdatePlannedToLocationPayload = string

export type UpdateAlternativePlannedToLocationPayload = string

export type UpdateOperatorsPlannedToLocationPayload = string

export type QuickShiftPayload = QuickShiftDto

export type FinishShiftMovePayload = FinishShiftMoveDto

export type FinishOutMovePayload = FinishOutMoveDto

export type FinishInMovePayload = FinishInMoveDto

export type ExportYardTasksPayload = ExportQueryDto

export type CreateShiftMovePayload = CreateShiftMoveDto

export type CancelPayload = CancelYardTaskDto

export type UpdateYardZonePayload = YardZoneUpdateDto

export type SetDefaultBlockPayload = DefaultBlockDto

export type ClearDefaultBlockPayload = DefaultBlockDto

export interface GetODataYardZonesParams {
  select?: string
  expand?: string
  filter?: string
  orderby?: string
  /** @format int32 */
  top?: number
  /** @format int32 */
  skip?: number
  count?: boolean
}

export type CreateYardZonePayload = YardZoneCreateDto

export interface GetYardTasksDocumentsParams {
  'api-version': any
  queryType?: any
  searchFields?: any
  searchMode?: any
  search?: any
  $count?: any
  $filter?: any
  $orderby?: any
  $top?: any
  $skip?: any
  facet?: any
}

export interface GetYardTasksDocumentsById0Params {
  'api-version': any
  documentId: any
}

export interface GetYardTasksDocumentsById1Params {
  'api-version': any
  documentId: any
}

export interface GetYardOrdersDocumentsParams {
  'api-version': any
  queryType?: any
  searchFields?: any
  searchMode?: any
  search?: any
  $count?: any
  $filter?: any
  $orderby?: any
  $top?: any
  $skip?: any
  facet?: any
}

export interface GetYardOrdersDocumentsById0Params {
  'api-version': any
  documentId: any
}

export interface GetYardOrdersDocumentsById1Params {
  'api-version': any
  documentId: any
}

export interface GetRulesDocumentsParams {
  'api-version': any
  queryType?: any
  searchFields?: any
  searchMode?: any
  search?: any
  $count?: any
  $filter?: any
  $orderby?: any
  $top?: any
  $skip?: any
  facet?: any
}

export interface GetRulesDocumentsById0Params {
  'api-version': any
  documentId: any
}

export interface GetRulesDocumentsById1Params {
  'api-version': any
  documentId: any
}

export interface GetNotificationSubscriptionDocumentsParams {
  'api-version': any
  queryType?: any
  searchFields?: any
  searchMode?: any
  search?: any
  $count?: any
  $filter?: any
  $orderby?: any
  $top?: any
  $skip?: any
  facet?: any
}

export interface GetNotificationSubscriptionDocumentsById0Params {
  'api-version': any
  documentId: any
}

export interface GetNotificationSubscriptionDocumentsById1Params {
  'api-version': any
  documentId: any
}
